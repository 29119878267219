import {
  actions,
  PLAY,
  PAUSE,
  UNMUTE,
  ADPLOGGER_SDK_INIT,
} from '../actions.js';

function dispatchPlayingState(id, state, store) {
  const { player } = store.getState();
  player.dispatchEvent(
    new CustomEvent('brick-player:playingstate', {
      detail: { id, state },
      bubbles: true,
    })
  );
  store.dispatch(actions.playingStateUpdated(state));
}

function setupPlayerEvents({ store, player }) {
  const { isScrollEnabled, adIsPaused, id } = store.getState();
  let events = [];
  const on = (eventName, cb) => {
    player.on(eventName, cb);
    events.push({ eventName, cb });
  };
  const off = (eventName, cb) => {
    player.off(eventName, cb);
    events = events.filter((e) => e.eventName !== eventName && e.cb !== cb);
  };
  const once = (eventName, cb) => {
    player.once(eventName, cb);
    events.push({ eventName, cb });
  };

  // if float on scroll, we do not want to pause ads
  if (!isScrollEnabled) {
    on('viewleave', () => {
      if (player.ads?.adPlaying) {
        store.dispatch(actions.adsPaused(true));
        player.ads.pause();
      }
    });
    on('viewenter', () => {
      if (player.ads && adIsPaused) {
        store.dispatch(actions.adsPaused(false));
        player.ads.resume();
      }
    });
  }
  on('ended', () => {
    const { nextVideo } = store.getState();
    if (nextVideo) {
      Object.entries(nextVideo).forEach(([key, value]) => {
        store.dispatch(actions.propertyUpdated(key, value));
      });
      store.dispatch(actions.propertyUpdated('nextVideo', null));
    }
    store.dispatch(actions.propertyUpdated('timeLeft', 0));
    dispatchPlayingState(id, 'end', store);
  });
  // Undocumented event, but reflects the css classNames added to the player.
  // This state is also available on the player object (player.playerState) - but then
  // in a non-reactive manner.
  on('state', ({ detail: playerState }) => {
    store.dispatch(actions.playerStateUpdated(playerState));
  });
  const onMuteCheck = (e) => {
    if (e.detail['is-muted'] === false) {
      store.dispatch(actions.unmute());
      off('state', onMuteCheck);
    }
  };
  on('state', onMuteCheck);

  // stop the player if another player starts playing
  const onPlayingState = ({ detail }) => {
    if (detail.state !== 'play') return;
    if (detail.id === id) return;
    player.pause();
  };
  window.addEventListener('brick-player:playingstate', onPlayingState);
  // forward/translate events to consumers
  on('playing', () => dispatchPlayingState(id, 'play', store));
  on('pause', () => dispatchPlayingState(id, 'pause', store));
  on('seeking', () => dispatchPlayingState(id, 'seek', store));
  on('seeked', () => dispatchPlayingState(id, 'seeked', store));
  // on player.destroy()
  on('reap', () => {
    window.removeEventListener('brick-player:playingstate', onPlayingState);
    events.forEach(({ eventName, cb }) => player.off(eventName, cb));
  });
  // keep track of how much time is left in the video
  on('timeupdate', () => {
    const { duration, currentTime } = player;
    const timeLeft = duration - currentTime;
    const { timeLeft: prevTimeleft, timeSince: prevTimeSince = 0 } =
      store.getState();

    if (Math.abs(timeLeft - prevTimeleft) >= 1) {
      store.dispatch(actions.propertyUpdated('timeLeft', timeLeft));
      dispatchPlayingState(id, 'timeupdate', store);
    }
    if (Math.abs(currentTime - prevTimeSince) >= 5) {
      store.dispatch(actions.propertyUpdated('timeSince', currentTime));
      dispatchPlayingState(id, 'timeupdate_5s', store);
    }
  });
  once('progress', () => store.dispatch(actions.playerStarted()));
  on('loadedmetadata', () => {
    const { player, adpLogger } = store.getState();
    if (adpLogger) {
      const element = player.closest('brick-player');
      store.dispatch({
        type: ADPLOGGER_SDK_INIT,
        payload: {
          adpLoggerInstance: adpLogger.createAdpLogger(element),
        },
      });
    }
  });

  [
    'ad-paused',
    'ad-resumed',
    'ad-started',
    'ad-skipped',
    'ad-completed',
  ].forEach((adEvent) => {
    on(adEvent, (ev) => {
      const { adpLoggerInstance } = store.getState();
      if (!adpLoggerInstance) return;

      const { ad_type: adType, ad, vast_ad_id } = ev.detail;

      // some properties are only available in ad-started and ad-ended events
      if (ad?.duration) {
        store.dispatch(
          actions.propertyUpdated('adDuration', ad.duration * 1000)
        );
      }
      if (vast_ad_id) {
        store.dispatch(actions.propertyUpdated('vastAdId', vast_ad_id));
      }

      const { adDuration, vastAdId } = store.getState();

      adpLoggerInstance.logVideoAdEvent({
        adpType: ev.type,
        adType,
        vastAdId,
        adDuration,
      });
    });
  });
}

export const playerMiddleware = (store) => (next) => (action) => {
  const { player: oldPlayer } = store.getState();
  const result = next(action);
  const { player } = store.getState();
  if (player && player !== oldPlayer) {
    setupPlayerEvents({ store, player });
  }
  if (action.type === PLAY) {
    player.play();
  }
  if (action.type === PAUSE) {
    player.pause();
  }
  if (action.type === UNMUTE) {
    player.muted = false;
  }
  return result;
};
